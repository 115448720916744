<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card>
          <v-layout wrap justify-center pa-2 px-8 text-justify>
            <v-flex xs12 text-center pt-4>
              <span class="tableValue">Indemnity Bond</span>
            </v-flex>
            <v-flex xs12 pt-2>
              I <b> {{ storage[0].name }} </b> aged
              <b> {{ getDoB(storage[0].dob) }} </b> Years, Resident of
              <b> {{ storage[0].nationality }} </b> do hereby agree and declare
              that:
            </v-flex>
            <v-flex xs12 pt-4>
              1. I, <b> {{ storage[0].name }} </b> whose date of birth is
              <b> {{ formatDate(storage[0].dob) }} </b> and my fellow visitors
              and any accompanying children (hereinafter called Participants)
              have applied to take part in safari/ jungle camp/trekking/ bamboo
              rafting or any activity (hereinafter as program) which involve
              risk, being organised by Parambikulam Tiger Conservation
              Foundation or their partners or associates (here-in-after called
              as PaTCoF).
            </v-flex>
            <v-flex xs12 pt-4 v-html="indemnityBond.Value"> </v-flex>
            <v-flex xs12 pt-4>
              Hence, signed on this day <b> {{ formatDate(new Date()) }} </b> at
              <b>{{ formatTime(new Date()) }}</b>
            </v-flex>
            <v-flex xs12 pt-4>
              I accept
              <router-link to="/TermsAndConditions" target="_blank">
                Terms and Conditions
              </router-link>
              &
              <router-link to="/TravelAndCancellationPolicy" target="_blank">
                Booking and Cancellation Policy
              </router-link>
            </v-flex>

            <v-flex xs12 py-4 text-right>
              <span class="px-2">
                <v-btn
                  dark
                  color="#FF4444"
                  small
                  depressed
                  :ripple="false"
                  @click="acceptConditions('close')"
                >
                  Close
                </v-btn>
              </span>
              <span class="px-2">
                <v-btn
                  dark
                  color="#53A874"
                  small
                  depressed
                  :ripple="false"
                  @click="acceptConditions('accept')"
                >
                  Accept
                </v-btn>
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      indemnityBond: {
        Value: "",
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        method: "GET",
        url: "/static/getbyname",
        params: {
          Name: "Indemnity Bond",
        },
      })
        .then((response) => {
          this.indemnityBond = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    acceptConditions(type) {
      if (type == "close") {
        this.$emit("stepper", {
          dialog: false,
          accept: false,
        });
      }
      if (type == "accept") {
        this.$emit("stepper", {
          dialog: false,
          accept: true,
        });
      }
    },
    getDoB(item) {
      var dob = new Date(item);
      var curDate = new Date();
      return Math.round(
        (curDate.getTime() - dob.getTime()) / (1000 * 3600 * 24 * 365)
      );
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + ", " + year + " ";
      return strTime;
    },
    formatTime(item) {
      var dt = new Date(item);
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>