var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":"#68D389","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '80vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '40vw'},model:{value:(_vm.packageConfirmationDialog),callback:function ($$v) {_vm.packageConfirmationDialog=$$v},expression:"packageConfirmationDialog"}},[_c('PackageConfirmation',{attrs:{"storage":_vm.storage.cart[0].quantity},on:{"stepper":_vm.winStepper}})],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{attrs:{"depressed":"","small":"","block":"","dark":"","ripple":false,"color":"#53A874"},on:{"click":_vm.validateInput}},[_c('span',{staticClass:"poppinsbold"},[_vm._v(" CHECKOUT")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }